import React, { useState } from "react";
import GatsbyLink from "gatsby-link";
import {
	TextContainer,
	Ul,
	Button,
	Icon,
	IconSearch,
	IconDownload,
	InlineText,
	Content,
	IconColumn,
	Paragraph,
} from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { Select } from "../../forms-and-inputs/select/select";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { InputFieldWithSlug } from "../../forms-and-inputs/input-field/input-field";
import { ToggleSwitch } from "../../actions-and-controls/toggle-switch/toggle-switch";
import { Popover } from "../../overlay/popover/popover";
import { ActionBar } from "../../actions-and-controls/action-bar/action-bar";
import { useFilterGroup, useSingleFilter } from "../filters/filters";
import { TableToolbar } from "./table-toolbar";

export const Meta: DocPageMeta = {
	category: "Tables and lists",
	title: "Table toolbar",
	notepad: "https://hackmd.io/0zisYVLIQPCAvEkWfBelsg",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Table toolbar"
			subTitle="A centralized location for managing and interacting with tabular data, offering functions like filtering, searching, exporting, and other table-related actions."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={TableToolbar} />
				<Header.H2>Examples</Header.H2>
				<Header.H3>Usage with actions</Header.H3>
				<Paragraph>
					Provide essential actions that operate on the entire table's data. Limit to two actions
					that directly support the table's main purpose.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>Place the most frequent or crucial actions first. </>,
						<>Use concise labels and relevant icons for easy identification.</>,
						<>
							Ideally, no more than two actions should be exposed as buttons. Use an{" "}
							<GatsbyLink to="/lab/components/Actions and controls/Action menu">
								Action Menu
							</GatsbyLink>{" "}
							for additional actions.
						</>,
					]}
				/>
				<Example fn={UsageWithActions} />
				<Header.H3>Usage with filter</Header.H3>
				<Paragraph>Allow users to refine table data based on specific criteria.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							Use a{" "}
							<GatsbyLink to="/lab/components/Tables and lists/Filters#filter-group">
								Filter Group
							</GatsbyLink>{" "}
							to house multiple filters, maintaining a clean toolbar.
						</>,
						<>
							When filters are active, display filter pills (removable tags) directly on the toolbar
							to indicate the applied criteria.
						</>,
					]}
				/>
				<Paragraph>
					Here is an example using a single filter that allow users to search for specific keywords
					(e.g country) within the table. Learn how to use filter groups in the guidelines for the{" "}
					<GatsbyLink to="/lab/components/Tables and lists/Filters">Filters</GatsbyLink> component.
				</Paragraph>
				<Example fn={UsageWithFilter} />
				<Header.H3>Usage with search</Header.H3>
				<Paragraph>Enable quick location of items or information within the table.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							Instant Feedback: Provide real-time visual feedback (e.g., highlighting or filtering
							results) as users type.
						</>,
						<>
							<GatsbyLink to="/lab/components/Forms and input/Auto Complete">
								Autocomplete
							</GatsbyLink>{" "}
							(optional): Consider an Autocomplete component to suggest search terms.
						</>,
					]}
				/>
				<Example fn={UsageWithSearch} />
				<Header.H3>Usage with custom views</Header.H3>
				<Paragraph>Allow users to personalize the table's appearance or data display.</Paragraph>
				<Header.H4>Button</Header.H4>
				<Paragraph>
					Opens a menu with predefined views or customization options (e.g., "Accessibility Issue"
					view).
				</Paragraph>
				<Example fn={UsageWithCustomViewsButton} />
				<Header.H4>Toggle switch</Header.H4>
				<Paragraph>
					Ideal for binary choices like "Compact" vs. "Detailed" view. (e.g. Performance {">"} Page
					overview)
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						"Use descriptive labels for each view.",
						"Provide a default view that suits most users.",
					]}
				/>
				<Example fn={UsageWithCustomViewsToggleSwitch} />
				<Header.H3>Usage with exports</Header.H3>
				<Paragraph>
					Enable users to download table data in various formats (CSV, Excel, etc.).
				</Paragraph>
				<Paragraph>
					Learn how to use the normal exporter and the CSV exporter in the guidelines for the{" "}
					<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink> component.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>Clearly label the button as "Export" or use a recognizable download icon.</>,
						<>For multiple export formats (CSV, Excel, etc.), use an Action menu</>,
					]}
				/>
				<Example fn={UsageWithExport} />
				<Header.H3>Usage with actions, filter, search, custom views and exports</Header.H3>
				<Paragraph>Combine all functionalities for comprehensive user control.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Logical Grouping</b>:
							<Ul
								items={[
									"Group related functions (e.g., actions on the left, filtering/search/views/export on the right).",
									"Use visual separators (divider or spacing) to distinguish groups.",
								]}
							/>
						</>,
						<>
							<b>Visual Hierarchy</b>: Employ spacing, size, and position to indicate the relative
							importance of actions.
						</>,
					]}
				/>
				<Example fn={UsageWithActionsFiltersSearchCustomViewsAndExports} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={TableToolbar}
					initialProps={{
						actions: <Button>Actions</Button>,
						filter: <Button>Filter</Button>,
						exports: <Button>Exports</Button>,
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>TableToolbar</Code> to
					</Paragraph>
					<Ul
						items={[
							"Display large datasets. Help users manage and navigate extensive information.",
							"Enable users to quickly refine data through filtering and search",
							"Provide custom views to accommodate user preferences.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>TableToolbar</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink> /
								<GatsbyLink to="/lab/components/Tables and lists/List table">List table</GatsbyLink>
								: Typically, the toolbar is placed directly above the table, making it easily
								accessible and visually associated with the table it controls
							</>,
							<>
								<GatsbyLink to="/lab/components/Overlay/Side panel">Side panel</GatsbyLink>: /
								Dashboard widgets: the toolbar can be placed at the top of the panel or widgets
								containing the table. This helps maintain a consistent layout and keeps related
								controls together.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H4>Style</Header.H4>
				<Ul
					items={[
						<>
							<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
							typography, and spacing. If you are not using a component from Fancy, match the
							styling of your <Code>TableToolbar</Code> to existing components for visual
							consistency.
						</>,
						<>
							Focus on actions that operate on the entire table and directly support its primary
							purpose.
						</>,
						<>
							Use universally recognized{" "}
							<GatsbyLink to="/lib/components/Visuals/Icon">icons</GatsbyLink> .
						</>,
						<>The toolbar should adapt seamlessly to different screen sizes and devices.</>,
						<>
							Order of Toolbar Items: (Left to right) Primary Actions {">"} Secondary Actions {">"}{" "}
							Filter {">"} Search {">"} Custom Views {">"} Export
						</>,
					]}
				/>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Inline actions are more suitable (e.g., editing individual cells).",
							"The table has too many functionalities, consider alternative interaction patterns.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient contrast, clear focus indicators, and descriptive labels/alt text for visual elements.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Ensure labels are concise and descriptive.",
							"Avoid jargon and technical terms.",
							<>
								Use verbs for button labels (e.g., "Add," "Cancel," "Export") from the{" "}
								<a href="https://fancy.siteimprove.com/Writing/word-list">Word list</a>{" "}
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const UsageWithActions = () => (
	<TableToolbar
		actions={
			<>
				<Button variant="primary" onClick={() => console.log("some primary action")}>
					Primary
				</Button>
				<Button variant="secondary" onClick={() => console.log("some secondary action")}>
					Secondary
				</Button>
			</>
		}
	/>
);

const UsageWithFilter = () => {
	type Country = { id: string; name: string };
	const countries: Country[] = [
		{ id: "DK", name: "Denmark" },
		{ id: "SE", name: "Sweden" },
		{ id: "NO", name: "Norway" },
		{ id: "FI", name: "Finland" },
	];
	const [country, setCountry] = useState<Country | undefined>(countries[0]);

	const onChange = (newValue: Country | undefined) => {
		console.log("Filter changed, calling API with new country", newValue);
		setCountry(newValue);
	};

	const [filterButton, activeFilters] = useSingleFilter(country, onChange, {
		label: "Country",
		name: "country",
		stringify: (country) => country?.name,
		items: countries.map((country) => ({ title: country.name, value: country })),
		compareFn: (a, b) => a.id === b.id,
	});

	return <TableToolbar filter={filterButton} activeFilters={activeFilters} />;
};

const UsageWithSearch = () => {
	const [searchValue, setSearchValue] = useState("");
	const searchTypeItems = ["URL", "Title"];
	const [selectedSearchType, setSelectedSearchType] = useState<string | undefined>(
		searchTypeItems[0]
	);

	return (
		<TableToolbar
			search={
				<InputFieldWithSlug
					aria-label="Search the table"
					placeholder="Search"
					name="searchInput"
					value={searchValue}
					onChange={setSearchValue}
					leftSlug={
						<Select
							items={searchTypeItems.map((x) => ({ value: x, title: x }))}
							value={selectedSearchType}
							onChange={setSelectedSearchType}
							hideClearButton
							aria-label="Search in URL or title"
						/>
					}
					rightSlug={
						<Button onClick={() => console.log(searchValue)} aria-label="Submit search">
							<Icon>
								<IconSearch />
							</Icon>
						</Button>
					}
				/>
			}
		/>
	);
};
const UsageWithCustomViewsButton = () => {
	return (
		<TableToolbar
			customViews={
				<Button>
					<Icon>
						<IconColumn />
					</Icon>
					<InlineText>Custom views</InlineText>
				</Button>
			}
		/>
	);
};

const UsageWithCustomViewsToggleSwitch = () => {
	const [toggleSwitchChecked, setToggleSwitchChecked] = useState<boolean>(false);

	return (
		<TableToolbar
			customViews={
				<ToggleSwitch label="Label" value={toggleSwitchChecked} onChange={setToggleSwitchChecked} />
			}
		/>
	);
};
const UsageWithExport = () => {
	return (
		<TableToolbar
			exports={
				<Button>
					<Icon>
						<IconDownload />
					</Icon>
					<InlineText>Export</InlineText>
				</Button>
			}
		/>
	);
};

const UsageWithActionsFiltersSearchCustomViewsAndExports = () => {
	// Search
	const [searchValue, setSearchValue] = useState("");
	const searchTypeItems = ["URL", "Title"];
	const [selectedSearchType, setSelectedSearchType] = useState<string | undefined>(
		searchTypeItems[0]
	);

	// Filters
	const countries = [
		{ id: "DK", name: "Denmark" },
		{ id: "SE", name: "Sweden" },
		{ id: "NO", name: "Norway" },
		{ id: "FI", name: "Finland" },
	];
	const devices = [
		{ id: "desktop", name: "Desktop" },
		{ id: "mobile", name: "Mobile" },
		{ id: "tablet", name: "Tablet" },
		{ id: "laptop", name: "Laptop" },
	];
	const [filters, setFilters] = useState({
		country: countries[0],
		devices: [devices[0], devices[1]],
	});

	const [filterGroup, activeFilters] = useFilterGroup(filters, setFilters, [
		{
			label: "Country",
			name: "country",
			property: "country",
			stringify: ({ country }) => country?.name,
			items: countries.map((country) => ({ title: country.name, value: country })),
			compareFn: (a, b) => a.id === b.id,
		},
		{
			label: "Devices",
			name: "devices",
			property: "devices",
			stringify: ({ devices }) =>
				(devices || []).length > 0 ? devices?.map((b) => b.name).join(", ") : undefined,
			items: devices.map((device) => ({ title: device.name, value: device })),
			compareFn: (a, b) => a.id === b.id,
		},
	]);

	return (
		<TableToolbar
			actions={
				<>
					<Button variant="primary" onClick={() => console.log("some primary action")}>
						Primary
					</Button>
					<Button variant="secondary" onClick={() => console.log("some secondary action")}>
						Secondary
					</Button>
				</>
			}
			filter={filterGroup}
			activeFilters={activeFilters}
			search={
				<InputFieldWithSlug
					aria-label="Search the table"
					placeholder="Search"
					name="searchInput"
					value={searchValue}
					onChange={setSearchValue}
					leftSlug={
						<Select
							items={searchTypeItems.map((x) => ({ value: x, title: x }))}
							value={selectedSearchType}
							onChange={setSelectedSearchType}
							hideClearButton
							aria-label="Search in URL or title"
						/>
					}
					rightSlug={
						<Button onClick={() => console.log(searchValue)} aria-label="Submit search">
							<Icon>
								<IconSearch />
							</Icon>
						</Button>
					}
				/>
			}
			customViews={
				<Popover
					popoverContent={(id) => (
						<div id={id}>
							<Content>Custom view content here</Content>
							<Popover.Footer>
								<ActionBar
									primary={{
										children: "Confirm",
										onClick: console.log,
									}}
									cancel={{
										children: "Cancel",
										onClick: console.log,
									}}
								/>
							</Popover.Footer>
						</div>
					)}
					buttonContent={
						<>
							<Icon>
								<IconColumn />
							</Icon>
							<InlineText>Custom views</InlineText>
						</>
					}
					hideChevron
				/>
			}
			exports={
				<Button>
					<Icon>
						<IconDownload />
					</Icon>
					<InlineText>Export</InlineText>
				</Button>
			}
		/>
	);
};
