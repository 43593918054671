import * as React from "react";
import { cn, DataObserveKey, Divider, VisualComponent } from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import * as scss from "./table-toolbar.scss";

type TableToolbarProps = {
	/** Actions/Buttons to be displayed in the table toolbar */
	actions?: React.ReactNode;
	/** Filter to be displayed in the table toolbar */
	filter?: React.ReactNode;
	/** Search to be displayed in the table toolbar */
	search?: React.ReactNode;
	/** Custom views to be displayed in the table toolbar */
	customViews?: React.ReactNode;
	/** Export options to be displayed in the table toolbar */
	exports?: React.ReactNode;
	/** Active filters */
	activeFilters?: React.ReactNode;
} & DataObserveKey &
	VisualComponent;

export const TableToolbar = (props: TableToolbarProps): JSX.Element => {
	const { actions, filter, search, customViews, exports, activeFilters, className, style } = props;
	const i18n = useLabTranslations();

	return (
		<div
			data-component="table-toolbar"
			data-observe-key={props["data-observe-key"]}
			className={cn(className, scss.tableToolbar, "fancy-FilterBar")}
			style={style}
			role="group"
			aria-label={i18n.tableToolbarLabel}
		>
			<div className={cn(scss.tableToolbarControls, "fancy-FilterBarControls")}>
				{actions && <div className={scss.left}>{actions}</div>}
				{(filter || search || customViews || exports) && (
					<div className={scss.right}>
						{filter}
						{search}
						{customViews}
						{exports}
					</div>
				)}
			</div>
			{activeFilters && (
				<>
					<Divider />
					{activeFilters}
				</>
			)}
		</div>
	);
};
