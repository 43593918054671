import React, { useState } from "react";
import {
	SpaceMedium,
	SpaceXxSmall,
	ColorBlueLighter,
	ColorBlue,
	ColorBlueLightest,
	ColorRedLightest,
	ColorRed,
	ColorRedLighter,
} from "../../design-tokens/variables";
import { DataObserveKey, VisualComponent } from "../../types/fancy-base-attributes";
import { content, layout } from "./content/content.scss";
import { grid, gridSection } from "./grid/grid.scss";

import * as scss from "./common.scss";

/** Values to align items to be used for alignItems|justifyContent|alignSelf */
export type FlexAlignment = "flex-start" | "center" | "flex-end" | "stretch";

export type Centered = "small" | "medium";

/** Values for the gap between items */
export type Gap = true | "xxSmall" | "xSmall" | "small" | "medium" | "large";

export type LayoutAndContentProps = {
	/** Custom id to be applied to the containing element */
	id?: string;
	/** Sets max-width (600px for small, 900px for medium) and margin: auto */
	centered?: Centered;
	/** Controls the gap between items */
	gap?: Gap;
	/** Controls alignment of items on cross axis. Not supported by IE11: first-baseline and last-baseline */
	alignItems?: FlexAlignment | "baseline" | "first baseline" | "last baseline";
	/** Controls space between and around items on main axis. Not supported by IE11: space-evenly */
	justifyContent?: FlexAlignment | "space-around" | "space-between" | "space-evenly";
	/** Controls space between and around items on cross axis (effect only visible when items wrap across multiple lines). Not supported by IE11: baseline, space-evenly, and stretch */
	alignContent?: FlexAlignment | "baseline" | "space-around" | "space-between" | "space-evenly";
	/** Controls direction of main axis */
	flexDirection?: "row" | "column";
	/** Controls whether items can wrap across multiple lines or not */
	flexWrap?: "wrap" | "nowrap";
} & DataObserveKey &
	VisualComponent;

export function centeredPropToClass(size: Centered | undefined): string | undefined {
	switch (size) {
		case "small":
			return scss.centeredSmall;
		case "medium":
			return scss.centeredMedium;
	}
	return undefined;
}

export function gapPropToClass(size: Gap | undefined): string | undefined {
	switch (size) {
		case "xxSmall":
			return scss.gapXxSmall;
		case "xSmall":
			return scss.gapXSmall;
		case "small":
			return scss.gapSmall;
		case "large":
			return scss.gapLarge;
		case "medium":
		case true:
			return scss.gapMedium;
	}
	return undefined;
}

const layoutStyle = `.${layout} { background-color: ${ColorBlueLightest}; outline: 1px solid ${ColorBlue}; outline-offset: -1px }`;
const contentStyle = `.${content} {	background-color: ${ColorBlueLighter}; outline: 1px solid ${ColorBlue}; outline-offset: -1px }`;
const contentChildStyle = `.${content} > * { outline: 1px dashed ${ColorBlue}; outline-offset: -1px }`;
const gridStyle = `.${grid} { background-color: ${ColorRedLightest}; outline: 1px solid ${ColorRed}; outline-offset: -1px }`;
const gridSectionStyle = `.${gridSection} { background-color: ${ColorRedLighter}; outline: 1px solid ${ColorRed}; outline-offset: -1px }`;
export const StructureExampleToggles = ({ id }: { id: string }): JSX.Element => {
	return (
		<div>
			<StyleToggle name="Grid" style={`#${id} ${gridStyle}`} />
			<StyleToggle name="Grid.Section" style={`#${id} ${gridSectionStyle}`} />
			<StyleToggle name="Layout" style={`#${id} ${layoutStyle}`} />
			<StyleToggle
				name="Content"
				style={[contentStyle, contentChildStyle].map((s) => `#${id} ${s}`).join(" ")}
			/>
		</div>
	);
};

export const StructureExampleToggle = (props: { id: string }): JSX.Element => {
	return (
		<StyleToggle
			name="Example styling"
			style={[layoutStyle, contentStyle, contentChildStyle, gridStyle, gridSectionStyle]
				.map((s) => `#${props.id} ${s}`)
				.join(" ")}
		/>
	);
};

function StyleToggle(props: { name: string; style: string }) {
	const [v, set] = useState(false);
	return (
		<>
			<label style={{ marginRight: SpaceMedium }}>
				<input
					style={{ margin: SpaceXxSmall, marginLeft: 0 }}
					type="checkbox"
					checked={v}
					onChange={() => set((s) => !s)}
				/>
				{props.name}
			</label>
			{v && <style>{props.style}</style>}
		</>
	);
}
