import React from "react";
import { Icon, IconFunnel, InlineText } from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import { Select, SelectProps } from "../../forms-and-inputs/select/select";

export type BaseFilterProps<T> = Omit<
	SelectProps<T>,
	| "fullWidth"
	| "hideChevron"
	| "hideClearButton"
	| "iconOnly"
	| "overviewLabel"
	| "showSelectionOverview"
> & {
	active?: boolean;
	label: string;
};

export type SingleFilterProps<T> = BaseFilterProps<T> & {
	stringify: (value: T) => string | undefined;
};

export function SingleFilter<T>(props: SingleFilterProps<T>): JSX.Element | null {
	const { active, label, stringify, ...rest } = props;
	const i18nLab = useLabTranslations();
	return active !== false ? (
		<Select
			aria-label={label}
			noDefaultOption={props.defaultOption === undefined}
			data-component="single-filter"
			listboxHeading={label}
			hideChevron
			hideClearButton
			buttonRenderer={() => (
				<>
					<Icon>
						<IconFunnel />
					</Icon>
					<InlineText>{i18nLab.filter}</InlineText>
				</>
			)}
			{...rest}
		/>
	) : null;
}
