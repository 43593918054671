import React, { ReactNode } from "react";
import { allChildrenAreOfType } from "../../../utils/react-utils";
import { logError } from "../../../utils/error-utils";
import { cn } from "../../../utils/shorthands";
import {
	centeredPropToClass,
	FlexAlignment,
	gapPropToClass,
	LayoutAndContentProps,
} from "../common";
import * as scss from "./content.scss";

export type ContentProps = LayoutAndContentProps & {
	children: ReactNode;
	/** Controls padding of container (defaults to medium) */
	padding?: "small" | "compact" | "medium" | "large" | "none";
	/** Overrides alignment of item on cross axis (controlled by alignItems property). Not supported by IE11: stretch */
	alignSelf?: FlexAlignment;
	/** Controls the size of an item. Shorthand for flex-grow, flex-shrink, and flex-basis (may be specified using one, two, or three values) */
	flex?: string;
	/** Removes top padding */
	noPaddingTop?: boolean;
	/** Removes bottom padding */
	noPaddingBottom?: boolean;
};

export function Content(props: ContentProps): JSX.Element {
	const {
		id,
		children,
		padding = "medium",
		noPaddingTop,
		noPaddingBottom,
		centered,
		flexDirection,
		flexWrap,
		justifyContent,
		alignItems,
		alignSelf,
		alignContent,
		gap,
		flex = 1,
		className,
		style,
	} = props;
	const anyDisplayFlexProp =
		!!flexDirection || !!justifyContent || !!alignItems || !!alignContent || !!gap;
	return (
		<div
			data-component="content"
			data-observe-key={props["data-observe-key"]}
			id={id}
			style={{
				display: anyDisplayFlexProp ? "flex" : "block",
				flexDirection,
				justifyContent,
				alignItems,
				alignSelf,
				alignContent,
				flex,
				paddingTop: !!noPaddingTop ? 0 : undefined,
				paddingBottom: !!noPaddingBottom ? 0 : undefined,
				flexWrap,
				...style,
			}}
			className={cn(
				scss.content,
				contentPaddingPropToClass(padding),
				gapPropToClass(gap),
				centeredPropToClass(centered),
				className
			)}
		>
			{children}
		</div>
	);
}

function contentPaddingPropToClass(size: "small" | "compact" | "medium" | "large" | "none") {
	switch (size) {
		case "small":
			return scss.paddingSmall;
		case "compact":
			return scss.paddingCompact;
		case "large":
			return scss.paddingLarge;
		case "none":
			return undefined;
	}
	return scss.paddingMedium;
}

Content.displayName = "Content";

type LayoutProps = LayoutAndContentProps & {
	/** Controls padding of the container (defaults to medium) */
	padding?: "small" | "medium" | "large" | "none";
	/** Content element(s) to be displayed insicde the layout container */
	children: React.ReactElement<ContentProps> | React.ReactElement<ContentProps>[];
};

function Layout(props: LayoutProps): JSX.Element {
	const {
		id,
		children,
		padding = "medium",
		gap,
		centered,
		alignItems,
		alignContent,
		justifyContent,
		flexDirection,
		flexWrap,
		className,
		style,
	} = props;

	if (!allChildrenAreOfType(children, Content)) {
		logError(`Children of "Layout" must be of type "Content"`);
	}

	return (
		<div
			data-component="layout"
			data-observe-key={props["data-observe-key"]}
			id={id}
			style={{
				justifyContent,
				alignContent,
				alignItems,
				flexDirection,
				flexWrap: flexWrap ? "wrap" : undefined,
				...style,
			}}
			className={cn(
				scss.layout,
				layoutPaddingPropToClass(padding),
				gapPropToClass(gap),
				centeredPropToClass(centered),
				className
			)}
		>
			{React.Children.map(children, (e, i) =>
				React.cloneElement(e, {
					key: e.key ?? i,
					padding: e.props.padding ?? paddingToChildPadding(padding),
				})
			)}
		</div>
	);
}

function paddingToChildPadding(size: "small" | "medium" | "large" | "none") {
	switch (size) {
		case "small":
			return "small";
		case "medium":
			return "small";
		case "large":
			return "medium";
		case "none":
			return "none";
	}
	return size;
}

function layoutPaddingPropToClass(size: "small" | "medium" | "large" | "none") {
	switch (size) {
		case "small":
			return scss.paddingSmall;
		case "large":
			return scss.paddingLarge;
		case "none":
			return undefined;
	}
	return scss.paddingMedium;
}

Content.Layout = Layout;
