import React from "react";
import { Icon, InlineText, IconClose, cn, DataObserveKey } from "@siteimprove/fancylib";
import { Pill } from "../../actions-and-controls/pill/pill";
import { SelectDto } from "../../forms-and-inputs/select/select-listbox";
import { useLabTranslations } from "../../../translations/translations";
import { dataObserveKeyDiscriminator } from "../../../utils/shorthands";
import { FilterItemProps } from "./filter-group";
import * as scss from "./active-filters.scss";

export type ActiveFiltersProps<T> = {
	filters: FilterItemProps<T>[];
	onClearFilter: (property: keyof T) => void;
	onClearAll?: () => void;
	value: T;
} & DataObserveKey;

export function ActiveFilters<T>(props: ActiveFiltersProps<T>): JSX.Element | null {
	const { filters, value, onClearFilter, onClearAll, ...rest } = props;
	const i18nLab = useLabTranslations();
	const interactiveFilters = filters.filter((filter) => isNonDefaultValue(filter, value));

	return (
		<div
			data-component="active-filters"
			className={cn(scss.activeFilters, "fancy-FilterBarActiveFilters")}
			role="group"
			aria-label={i18nLab.activeFilters}
			{...rest}
		>
			{filters.map((filter) => {
				const { label, property, stringify, "data-observe-key": dataObserveKey } = filter;
				return isNonDefaultValue(filter, value) ? (
					<Pill
						variant={{
							type: "interactive",
							onClick: () => onClearFilter(property),
							srOnlyText: i18nLab.clickToRemove,
						}}
						key={label}
						data-observe-key={dataObserveKeyDiscriminator(dataObserveKey, "Clear")}
					>
						<InlineText emphasis="strong">{label}: </InlineText>
						{stringify(value)}
						<Icon>
							<IconClose />
						</Icon>
					</Pill>
				) : (
					<Pill variant={{ type: "static" }} key={label}>
						<InlineText emphasis="strong">{label}: </InlineText>
						{stringify(value)}
					</Pill>
				);
			})}
			{onClearAll !== undefined && interactiveFilters.length > 1 && (
				<Pill
					variant={{
						type: "interactive",
						onClick: onClearAll,
					}}
					data-observe-key={dataObserveKeyDiscriminator(props["data-observe-key"], "ClearAll")}
				>
					{i18nLab.clearAll}
					<Icon>
						<IconClose />
					</Icon>
				</Pill>
			)}
		</div>
	);
}

function isNonDefaultValue<T>(filter: FilterItemProps<T>, value: T): boolean {
	return !Array.isArray(value[filter.property]) &&
		filter.defaultOption !== undefined &&
		filter.compareFn
		? !filter.compareFn(
				value[filter.property] as SelectDto<T[keyof T]>,
				filter.defaultOption as SelectDto<T[keyof T]>
		  )
		: value[filter.property] !== filter.defaultOption;
}
